import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Box, Flex, Text, Heading3, Button, PrimaryButton, DangerButton, QuietButton } from "@nulogy/components";
import { config } from "../../playgrounds/button";
import { COPY_WIDTH } from "../../components/CONSTANTS";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Button`}</h1>
    <p>{`Buttons make common actions immediately detectable and easy to perform.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Types of buttons`}</h2>
    <p>{`There are three other types of buttons for different contexts, which all share the same properties as `}<inlineCode parentName="p">{`Button`}</inlineCode>{`. They can be imported as their own components, e.g:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import * as React from "react";
import { QuietButton } from "@nulogy/components";

export default () => {
  return <PrimaryButton>Create project</PrimaryButton>;
};
`}</code></pre>
    <Flex maxWidth={COPY_WIDTH} m="0 auto" alignItems="center" justifyContent="space-between" mb="x4" mdxType="Flex">
  <Box maxWidth="75%" mdxType="Box">
    <Heading3 mdxType="Heading3">PrimaryButton</Heading3>
    <Text fontSize="20px" mdxType="Text">
      PrimaryButtons are used for the main action in a particular context. There
      is usually not more than one primary button per screen and not all of the
      screens require a Primary button.
    </Text>
  </Box>
  <Box mdxType="Box">
    <PrimaryButton mdxType="PrimaryButton">Create project</PrimaryButton>
  </Box>
    </Flex>
    <Flex maxWidth={COPY_WIDTH} m="0 auto" alignItems="center" justifyContent="space-between" mb="x4" mdxType="Flex">
  <Box maxWidth="75%" mdxType="Box">
    <Heading3 mdxType="Heading3">DangerButton</Heading3>
    <Text fontSize="20px" mdxType="Text">
      DangerButtons are used for destructive actions such as deleting. They are
      most likely to appear in confirmation dialogs.
    </Text>
  </Box>
  <Box mdxType="Box">
    <DangerButton mdxType="DangerButton">Cancel project</DangerButton>
  </Box>
    </Flex>
    <Flex maxWidth={COPY_WIDTH} m="0 auto" alignItems="center" justifyContent="space-between" mdxType="Flex">
  <Box maxWidth="75%" mdxType="Box">
    <Heading3 mdxType="Heading3">QuietButton</Heading3>
    <Text fontSize="20px" mdxType="Text">
      QuietButtons are used for less important actions such as “Cancel” or
      actions that are not directly related to the context of the page (e.g
      Learn more …). Quiet buttons are often paired with a Primary button.
    </Text>
  </Box>
  <Box mdxType="Box">
    <QuietButton mdxType="QuietButton">See more details</QuietButton>
  </Box>
    </Flex>
    <h2>{`Content and UX guidelines`}</h2>
    <h3>{`Labelling`}</h3>
    <ul>
      <li parentName="ul">{`Always lead with an actionable verb.`}</li>
      <li parentName="ul">{`Whenever possible follow with a clear noun (e.g: Create shipment, Approve delivery).`}</li>
      <li parentName="ul">{`Always use sentence case.`}</li>
    </ul>
    <h3>{`Grouping`}</h3>
    <ul>
      <li parentName="ul">{`Use the `}<a parentName="li" {...{
          "href": "/components/button-group"
        }}>{`ButtonGroup`}</a>{` component to associate a group of buttons together.`}</li>
      <li parentName="ul">{`Order buttons in a group from most important on the left to least important on the right.`}</li>
      <li parentName="ul">{`Only have one `}<inlineCode parentName="li">{`PrimaryButton`}</inlineCode>{` or `}<inlineCode parentName="li">{`DangerButton`}</inlineCode>{` on the page at one time`}</li>
    </ul>
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/button-group"
        }}>{`ButtonGroup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/iconic-button"
        }}>{`IconicButton`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      